@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Space+Grotesk:wght@300;400;500;600;700&display=swap);
html {
  background-position: 0% 0%;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

html[data-theme='light-mode'] {
  background: radial-gradient(150% 150% at 50% 0%, #FF9EF250 0%, #FFFFFF 50%, #FFFFFF 100%);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

html[data-theme='dark-mode'] {
  -webkit-filter: invert(0.9) hue-rotate(180deg);
          filter: invert(0.9) hue-rotate(180deg);
  background: radial-gradient(150% 150% at 50% 0%, #ffb6f8 0%, #ffffff 50%, #ffffff 100%);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

body {
  font-family: 'Manrope', sans-serif;
  margin: 0;
}
h1, h2, h3, h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.display:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  opacity: 0.5;
  background: 
      linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 70%),
      url("https://cdn.quorumdao.org/background-2.png");
  box-shadow: inset 0 0 0 2000px rgba(224, 224, 224, 0.75);
  -webkit-filter: brightness(125%);
          filter: brightness(125%);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
  z-index: -1;
}

.display {
  margin: 0;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.gridColumn .overview:not(:last-child) {
  margin-bottom: 15px;
}

.core {
  flex-direction: row;
  justify-content: center;
  align-items: start;
  margin: auto;
  grid-gap: 20px;
  margin-top: 0;
  margin-bottom: auto;
}

.overview {
  border-radius: 15px;
  padding: 15px 0px 15px 0px;
  border: 1px solid #F0F0F0;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  min-width: 560px;
}

.overviewHeader {
  padding: 0px 0px 15px 20px;
}

.overviewHeader div {
  border-top: 1px solid #F0F0F0;
  margin-top: 15px;
  margin-left: -20px;
}

.overviewHeader h1 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 20px;
}

.overviewGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px 0px;
}

.overviewGrid div {
  padding: 10px 20px;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.overviewGrid h2:hover {
  color: #C3C3C3;
  cursor: pointer;
  width: -webkit-max-content;
  width: max-content;
}

.overviewGrid h1 {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.05ch;
  font-family: 'Space Grotesk', sans-serif;
  color: #000000;
}

.overviewGrid h2 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 0.5rem;
  width: -webkit-max-content;
  width: max-content;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.overviewGrid h3 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #000000;
  margin-top: 0.5rem;
}

.announcementBar {
  background-color: #FF7BED;
  text-align: center;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  display: block;
  max-width: 100%;
  color: #FFFFFF;
  font-size: 0.85rem;
  font-weight: 600;
  padding: 8px 12px;
  text-decoration: none;
}

.announcementBar:hover {
  background-color: #ff9af1;
}

.header {
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
  margin: 8px auto 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px 0px 40px;
  min-height: 83px;
}

.headerLeft {
  display: flex;
  align-items: center;
}

.headerLeft a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.headerLeft img {
  cursor: pointer;
  display: block;
}

.headerLeft h1 {
  border-radius: 8px;
  font-size: 0.75rem;
  margin-left: 0.75rem;
  font-weight: 600;
  line-height: 0.5;
  color: #FFFFFF;
  background-color: #000000;
  padding: 0.5rem;
}

.headerLeft ul {
  box-sizing: border-box;
  margin: 0px 36px;
  min-width: 0px;
  display: grid;
  grid-gap: 0px 24px;
  gap: 0px 24px;
  grid-auto-flow: column;
  padding: 0px;
  list-style: none;
}

.headerLeft li {
  font-size: 1rem;
  font-weight: 600;
  color: #838383;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  cursor: pointer;
}

.headerLeft li:hover {
  color: #000000;
}

.headerButton {
  display: inline-block;
  width: auto;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0.1rem 1.25rem;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  font-size: 0.85em;
  font-weight: 600;
  border-radius: 2.5rem;
  background-color: #000000;
  color: #FFFFFF;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
}

.headerButton:hover {
  background-color: #575757 !important;
  cursor: pointer;
}

.headerButton img {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  width: 17px;
  vertical-align: sub;
  padding: 10px;
  border-radius: 100px;
  overflow: initial;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.headerButtonWhite {
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  color: #000000;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.headerButtonWhite:hover {
  background-color: #FFFFFF !important;
  border-color: #c3c3c3;
}

.headerButtonWhite:hover img {
  -webkit-filter: brightness(0);
          filter: brightness(0);
}

.headerMenu {
  position: absolute;
  top: 114.5px; /* 80px */
  right: 40px;
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  padding: 5px;
  z-index: 1;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  opacity: 0;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.headerMenu.visible {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.headerMenu div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  -webkit-user-select: none;
  user-select: none;
}

.headerMenu div:hover {
  background-color: #F0F0F0;
  cursor: pointer;
}

.headerMenu h1 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0;
}

.headerMenu h2 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 0;
  margin-left: 1rem;
}

.headerMenu .divider {
  margin: 0.25rem -5px;
  padding: 0;
  border-bottom: 1px solid #F0F0F0;
}

.headerMenu .divider:hover {
  cursor: default;
}

.headerOnMobile {
  display: none !important;
}

.smallButton {
  display: inline-block;
  width: auto;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.75rem;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 2.5rem;
  background-color: #000000;
  color: #FFFFFF;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 0;
  margin-left: auto;
}

.smallButton:hover {
  background-color: #575757 !important;
  cursor: pointer;
}

.borrowInput {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #F0F0F0;
  outline: 0px;
  min-width: 250px;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.borrowInput:hover {
  border: 1px solid #c3c3c3;
}

.borrowInput:focus-within {
  border: 1px solid #838383;
}

.borrowInput input {
  font-family: 'Manrope', sans-serif;
  font-size: 1.25em;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  padding: 15px;
  outline: 0px;
  width: 100%;
}

.borrowInput h1 {
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
}

.top {
  margin-top: 2.25rem;
  margin-bottom: 6rem;
  text-align: center;
}

.top h1 {
  color: #000000;
  font-size: 3.25rem;
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: -0.05ch;
}

.top span {
  color: #FF5C5C;
  background-image: linear-gradient(104deg, #FF5CD1 13%, #FF5C5C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 5px;
}

.footer {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 48px;
}

.footer div {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  margin-bottom: 0.75rem;
}

.footer span {
  width: 8px;
  height: 8px;
  background-color: #838383;
  border-radius: 100px;
  display: block;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.footer span:hover {
  opacity: 0.5;
  cursor: pointer;
}

.footer h1 {
  font-size: 0.75em;
  color: #000000;
  line-height: 1.5;
  font-weight: 600;
}

.footer h2 {
  font-size: 0.75em;
  color: #838383;
  line-height: 1.5;
  font-weight: 600;
  margin-top: 1.5rem;
}

.footer a {
  text-decoration: none;
  color: #838383;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.footer a:hover {
  color: #000000;
}

.footer ul {
  grid-gap: 0.75rem;
  gap: 0.75rem;
  display: flex;
  justify-content: center;
  padding: 0px;
  list-style: none;
  margin: 0;
}

.footer ul:not(:first-child) {
  margin-top: 6px;
}

.footer ul:not(:last-child) {
  margin-bottom: 6px;
}

.footer li {
  font-size: 0.75em;
  color: #838383;
  line-height: 1.5;
  font-weight: 600;
  margin-top: 0;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.footer li:hover {
  color: #000000;
  cursor: pointer;
}

.footer li a {
  text-decoration: none;
  color: #838383;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.footer li a:hover {
  color: #000000;
}

.navLink {
  text-decoration: none;
  color: #838383;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  -webkit-user-select: none;
  user-select: none;
}

.navLink:hover {
  color: #000000;
}

.navLink.active {
  color: #000000;
}

.loader {
  flex-direction: row;
  justify-content: center;
  align-items: start;
  margin: auto;
  justify-items: center;
  display: grid;
}

.loader h1 {
  margin-bottom: 0.5rem;
}

.loader h2 {
  font-size: 1rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 2.5rem;
}

.loader a {
  display: inline-block;
  width: auto;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 1.25rem;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 2.5rem;
  background-color: #000000;
  color: #FFFFFF;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.loader a:hover {
  background-color: #575757 !important;
}

.error {
  width: 30%;
}

.error img {
  width: 50px;
  padding: 5px;
  border-radius: 100px;
  background-color: #FF7BED25;
  margin-bottom: 1rem;
}

.vaultHeaderTitle div {
  display: block !important;
}

.vaultHeaderTitle h1 {
  margin-bottom: 5px;
}

.vaultHeader div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vaultHeader h1 {
  color: #000000;
  font-size: 1.25em;
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: -0.05ch;
}

.vaultHeader h2 {
  color: #838383;
  font-size: 0.85em;
  line-height: 1.1;
  font-weight: 600;
}

.vaultHeader a {
  color: #838383;
  font-size: 0.85em;
  line-height: 1.1;
  font-weight: 600;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
}

.vaultHeader a:hover {
  color: #000000;
  cursor: pointer;
}

.vaultHeaderDivider {
  border-top: 1px solid #00000010;
  margin-top: 15px;
  margin-bottom: 30px;
}

.gridDivider {
  border-top: 1px solid #F0F0F0;
  margin-top: 15px;
  margin-bottom: 15px;
}

.overviewGrid2 {
  padding: 0px 20px 0px 20px;
}

.overviewGrid2 div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overviewGrid2 h1 {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.05ch;
  color: #000000;
}

.overviewGrid2 h2, .overviewGrid2 a {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
}

.overviewGrid2Divider {
  border-top: 1px solid #F0F0F0;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: -20px;
  margin-right: -20px;
}

.overviewGrid2 a {
  text-decoration: none;
  color: #000000;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.overviewGrid2 a:hover {
  color: #838383;
}

.defaultTooltip {
  font-weight: 600;
  line-height: 1.1;
  border-radius: 12px !important;
  opacity: 0 !important;
  visibility: visible;
  z-index: 1111 !important;
  background-color: #FFFFFF !important;
  color: #000000 !important;
  border: 1px solid #F0F0F0 !important;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
}

.defaultTooltip::before {
  z-index: -1 !important;
}

.defaultTooltip::after {
  border: 1px solid #F0F0F0 !important;
  background-color: #FFFFFF !important;
}

.defaultTooltip.show {
  visibility: visible;
  opacity: 1 !important;
}

.defaultTooltip .multi-line {
  text-align: left !important;
  font-size: 0.85rem;
  font-weight: 600;
}

.discoverInput {
  border-radius: 10px;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  font-family: 'Manrope', sans-serif;
  font-size: 1em;
  font-weight: 600;
  height: 48px;
  outline: 0px;
  min-width: 250px;
  padding-left: 15px;
  display: flex;
  margin-bottom: 1rem;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.discoverInput:hover {
  border: 1px solid #c3c3c3;
}

.discoverInput:focus-within {
  border: 1px solid #838383;
}

.discover {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10rem;
}

.discover h1 {
  margin-bottom: 0.5rem;
}

.discover h2 {
  font-size: 1rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 2.5rem;
}

.discover button {
  display: inline-block;
  width: auto;
  height: 48px;
  line-height: 3rem;
  padding: 0 1.25rem;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 100px;
  background-color: #000000;
  color: #FFFFFF;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border: none;
}

.discover button:hover {
  background-color: #575757 !important;
  cursor: pointer;
}

.discover span {
  margin-top: 1.5rem;
  color: #000000;
  font-size: 0.75em;
  font-weight: 600;
}

.popupModal {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: radial-gradient(150% 150% at 50% 0,#FF9EF250 0,#fff 50%,#fff 100%);
  z-index: 800;
  opacity: 0;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  max-height: 100vh;
  overflow: auto;
}

.popupModal.visible {
  opacity: 1;
}

.popupHeader {
  padding: 20px;
  border-bottom: 1px solid #F0F0F0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popupHeader h1 {
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
}

.popupHeader svg {
  cursor: pointer;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.popupHeader svg:hover {
  color: #838383;
}

.popupBody {
  text-align: center;
  margin: 2rem;
}

.popupBody img {
  width: 50px;
  padding: 5px;
  border-radius: 100px;
  background-color: #FF7BED25;
  margin-bottom: 0.75rem;
}

.popupBody h1 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.popupBody h2 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 1.75rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.popupBody h3 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
}

.popupBody a {
  text-decoration: none;
  font-size: 0.75em;
  color: #000000;
  line-height: 1;
  font-weight: 600;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.popupBody a:hover {
  color: #838383;
  cursor: pointer;
}

.popupOuter {
  margin: auto;
  display: flex;
  opacity: 0;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.popupOuter.visible {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.popupContent {
  min-width: 450px;
  max-width: 450px;
  border-radius: 15px;
  border: 1px solid #F0F0F0;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.popupTable {
  text-align: left;
  padding: 15px;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
}

.popupTableContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popupTable h1 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0;
}

.popupTable h2 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 0;
}

.apiTable {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  margin-bottom: 10px;
}

.apiTable a {
  color: #FFFFFF;
  background-color: #838383;
  text-decoration: none;
  line-height: 2.5rem;
  margin: 0.1rem;
  font-size: 0.85rem;
  border-radius: 5px;
  width: 100%;
}

.apiTable a:hover {
  color: #FFFFFF;
}

.apiTable a.active {
  background-color: #000000;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.pendingTxnHome {
  min-width: 560px;
  border: 1px solid #F0F0F0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
}

.pendingTxnHome img {
  width: 30px;
  min-height: 30px;
  background-color: #F0F0F050;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  padding: 5px;
  margin-right: 1rem;
}

.pendingTxnHome h1 {
  font-size: 1rem;
  color: #000000;
  font-weight: 600;
}

.pendingTxnHome h2 {
  font-size: 0.85rem;
  color: #838383;
  font-weight: 600;
}

@media only screen and (max-device-width: 700px) {
  
  body {
    margin: 0;
  }
  
  .header {
    justify-content: center;
    padding: 0px;
    min-height: 62.25px;
  }

  .headerLeft {
    padding: 0px 20px;
    width: 100%;
    justify-content: space-between;
  }

  .headerLeft ul {
    display: none;
  }

  .headerRight {
    padding-right: 20px;
  }

  .headerButtonWhite {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .headerButtonWhite img {
    padding: 5px;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }

  .headerMenu {
    top: 100px; /* 65px */
    right: 20px;
  }

  .headerOnMobile {
    display: block !important;
  }

  .top {
    padding: 0px 40px;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  .core {
    width: 100%;
    
  }

  .grid {
    grid-template-columns: 1fr;
    padding: 0px 20px;
  }

  .gridColumn {
    display: contents;
    margin: unset;
  }

  .gridColumn .overview:not(:last-child) {
    margin-bottom: unset;
  }

  .o1 {
    order: 0;
  }

  .o2 {
    order: 1;
  }

  .o3 {
    order: 2;
  }

  .o4 {
    order: 3;
  }

  .o5 {
    order: 4;
  }

  .o6 {
    order: 5;
  }

  .o7 {
    order: 6;
  }

  .o8 {
    order: 7;
  }

  .overview {
    min-width: 100%;
  }

  .grid2 {
    padding: 20px;
    min-width: unset !important;
  }

  .overviewGrid {
    grid-template-columns: 1fr;
  }

  .discover {
    padding: 0px 60px;
  }

  .loader {
    padding: 0px 60px;
    text-align: center;
  }

  .error {
    width: auto;
  }

  .popupOuter {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }

  .popupContent {
    min-width: 100%;
  }

  .display:before {
    background-size: 100vh;
  }

  .pendingTxnHome {
    display: grid;
    grid-gap: 15px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    max-width: 100%;
  }

  .pendingTxnHome a {
    margin: unset;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .pendingTxnHome h1 {
    margin-bottom: 5px;
  }

  .pendingTxnHomeMobile {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.switchComponentOuter {
  min-width: 44px;
  min-height: 24px;
  position: relative;
  border-radius: 17px;
  background-color: #c3c3c3;
  transition: 0.4s;
  cursor: pointer;
}

.switchComponent {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.switchComponentOuter:hover .switchComponent {
  background-color: #F0F0F0;
}

.switchComponentOuter.active {
  background-color: #FF7BED;
}

.switchComponentOuter.active .switchComponent {
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
}

.switchComponentOuter.disabled {
  background-color: #d9d9d9;
  cursor: default;
}

.switchComponentOuter.disabled .switchComponent {
  background-color: #F0F0F0;
}

@-webkit-keyframes pulse {
    0% {
      opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes pulse {
    0% {
      opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.alertDot {
  width: 8px;
  height: 8px;
  background-color: #838383;
  border-radius: 100px;
  display: block;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.alertDot:hover {
  opacity: 0.5;
  cursor: pointer;
}
