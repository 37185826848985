@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

html {
  background-position: 0% 0%;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

html[data-theme='light-mode'] {
  background: radial-gradient(150% 150% at 50% 0%, #FF9EF250 0%, #FFFFFF 50%, #FFFFFF 100%);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

html[data-theme='dark-mode'] {
  filter: invert(0.9) hue-rotate(180deg);
  background: radial-gradient(150% 150% at 50% 0%, #ffb6f8 0%, #ffffff 50%, #ffffff 100%);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

body {
  font-family: 'Manrope', sans-serif;
  margin: 0;
}